import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';

function ObjectiveCell({ name }) {
  const classes = useStyles();
  const objectiveTextRef = useRef();
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    setIsOverflowed(
      objectiveTextRef.current.scrollWidth >
        objectiveTextRef.current.clientWidth
    );
  }, []);

  return (
    <Tooltip
      title={name}
      disableHoverListener={!isOverflowed}
      classes={{ tooltip: classes.tooltip }}
    >
      <div ref={objectiveTextRef} className={classes.col}>
        {name}
      </div>
    </Tooltip>
  );
}

ObjectiveCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ObjectiveCell;
