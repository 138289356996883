/* eslint-disable react/no-danger */
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AutoLaTeX from 'react-autolatex';
import Answer from './answer';
import useStyles from './styles';

// TODO: Review these options. This was taken from the existing ProseMirror example app.
const autoLatexOptions = {
  delimiters: [{ left: '$', right: '$', display: false }],
  errorCallback(msg, err) {
    // eslint-disable-next-line no-console
    console.error('[Error]', msg, err);
  },
};

function PreviewContent({ fields }) {
  const classes = useStyles();
  const answerRef = useRef();
  const misconception1Ref = useRef();
  const misconception2Ref = useRef();
  const misconception3Ref = useRef();

  const [height, setHeight] = useState();
  const [waiting, setWaiting] = useState(true); // Initially cover the content until it's ready
  const currentMaxHeight = useRef(0);
  const resizeTimeout = useRef();

  // Handle resize of any answer containers
  const resizeObserver = new ResizeObserver((entries) => {
    // Save tallest of the new resizes
    Object.keys(entries).forEach((key) => {
      if (entries[key].contentRect.height > currentMaxHeight.current) {
        currentMaxHeight.current = entries[key].contentRect.height;
      }
    });

    // Reset an existing timer but waiting from the latest resize.
    if (resizeTimeout.current) {
      clearTimeout(resizeTimeout.current);
    }

    // Wait for half a second after last resize
    // Assumes no other resize would happen beyond half a second.
    // Then set to height of each answer to the highest value we recorded.
    resizeTimeout.current = setTimeout(() => {
      setHeight(currentMaxHeight.current);
      setWaiting(false); // Reveal the content
    }, 500);
  });

  // Listen to resizes in any of the answer containers
  useEffect(() => {
    if (answerRef.current) {
      resizeObserver.observe(answerRef.current);
    }
    if (misconception1Ref.current) {
      resizeObserver.observe(misconception1Ref.current);
    }
    if (misconception2Ref.current) {
      resizeObserver.observe(misconception2Ref.current);
    }
    if (misconception3Ref.current) {
      resizeObserver.observe(misconception3Ref.current);
    }

    return () => {
      setWaiting(true);
      clearTimeout(resizeTimeout);
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fields ? (
    <div>
      {waiting && <div className={classes.waiting} />}
      <AutoLaTeX className={classes.questionText} options={autoLatexOptions}>
        {fields.title}
      </AutoLaTeX>
      <div className={classes.cols}>
        <div className={classes.column}>
          <div ref={answerRef}>
            <Answer html={fields.answer} isCorrect height={height} />
          </div>
          <div ref={misconception1Ref}>
            <Answer html={fields.misconception1} height={height} />
          </div>
        </div>
        <div className={classes.column}>
          <div ref={misconception2Ref}>
            <Answer html={fields.misconception2} height={height} />
          </div>
          <div ref={misconception3Ref}>
            <Answer html={fields.misconception3} height={height} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

PreviewContent.defaultProps = {
  fields: null,
};

PreviewContent.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    answer: PropTypes.string,
    misconception1: PropTypes.string,
    misconception2: PropTypes.string,
    misconception3: PropTypes.string,
  }),
};

export default PreviewContent;
