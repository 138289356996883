import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #DBE3EE',
    borderRadius: '16px',
    padding: '0 10px',
    marginBottom: '14px',
  },
  correct: {
    borderColor: '#67D146',
  },
}));

export default useStyles;
