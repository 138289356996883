import { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useStore from './store';

const useStyles = makeStyles(() => ({
  iframe: {
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
  authenticated: {
    display: 'none',
  },
}));

const authAppUrl = process.env.REACT_APP_AUTH_APP_URL;
const selector = (state) => [state.jwt, state.setJwt];

const AuthWrapper = ({ children }) => {
  const classes = useStyles();
  const [jwt, setJwt] = useStore(selector);
  const iframeRef = useRef();

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe.onload = () =>
      iframe.contentWindow.postMessage(
        { type: 'SUBSCRIBE_AUTH_STATE' },
        authAppUrl
      );

    const onMessage = (e) => {
      if (e.source === window || e.origin !== authAppUrl) return;
      if (e.data.type !== 'AUTH_STATE_UPDATED') return;
      setJwt(e.data.jwt);
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [setJwt]);

  return (
    <>
      <iframe
        ref={iframeRef}
        src={authAppUrl}
        className={`${classes.iframe} ${jwt ? classes.authenticated : ''}`}
        frameBorder="0"
        height="100%"
        width="100%"
        title="Intentionally blank"
        aria-hidden="true"
      />
      {jwt && children}
    </>
  );
};

export default AuthWrapper;
