import create from 'zustand';
import { QUESTION_STATUS } from '../constants';

function setQuestionCounts(objective) {
  const questionCounts = {};
  for (const status of Object.values(QUESTION_STATUS)) {
    questionCounts[status] = 0;
  }
  for (const q of objective.questions) questionCounts[q.status]++;
  return { ...objective, questionCounts };
}

export default create((set) => ({
  objectives: [],
  setObjectives: (objectives) =>
    set({
      objectives: objectives.map((objective) => {
        const objectiveCode = [
          `${objective.syllabusCode}${objective.stage}`,
          objective.unitOrderInStage,
          objective.statementOrderInUnit,
          objective.orderInStatement,
        ].join('.');
        return {
          ...objective,
          assignedTo: objective.author?.name ?? '',
          objectiveCodeAndName: `${objectiveCode} ${objective.name}`,
        };
      }),
    }),
  setQuestions: (objectiveId, questions) =>
    set((state) => {
      const objectives = state.objectives.map((objective) =>
        objective.id !== objectiveId
          ? objective
          : setQuestionCounts({ ...objective, questions })
      );
      set({ objectives });
    }),
  addQuestion: (objectiveId, question) =>
    set((state) => {
      const objectives = state.objectives.map((objective) =>
        objective.id !== objectiveId
          ? objective
          : setQuestionCounts({
              ...objective,
              questions: [...objective.questions, question],
            })
      );
      set({ objectives });
    }),
  editQuestion: (question) =>
    set((state) => {
      const objectives = state.objectives.map((objective) => {
        if (objective.id !== question.objectiveId) return objective;

        const questions = objective.questions.map((q) =>
          q.id !== question.id ? q : question
        );
        return setQuestionCounts({ ...objective, questions });
      });
      set({ objectives });
    }),
}));
