import create from 'zustand';
import { EDITOR_ROLES, AUTHOR_ROLES } from './constants';

function hasRole(roles, user) {
  return roles.filter((role) => user.roles.includes(role)).length > 0;
}

export default create((set, get) => ({
  jwt: null,
  user: null,
  role: null,
  setJwt: (jwt) =>
    set(() => {
      const user = jwt ? JSON.parse(atob(jwt.split('.')[1])).user : null;
      set({ jwt, user });
    }),
  isEditor: () => hasRole(EDITOR_ROLES, get().user),
  isAuthor: () => hasRole(AUTHOR_ROLES, get().user),
}));
