import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

function ObjectiveContext({ objective }) {
  const { id, objectiveCodeAndName, statementName, topicName } = objective;
  const objectiveRoute = `https://curriculum.completemaths.com/objectives/${id}?page=exQuestions`;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>
                <strong>Objective</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Link
                href={objectiveRoute}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>{objectiveCodeAndName}</Typography>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>
                <strong>Statement</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{statementName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>
                <strong>Topic</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{topicName}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ObjectiveContext.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.string,
    objectiveCodeAndName: PropTypes.string,
    statementName: PropTypes.string,
    topicName: PropTypes.string,
  }).isRequired,
};

export default ObjectiveContext;
