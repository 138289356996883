import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  dashboard: {
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },
});

export default makeStyles(styles);
