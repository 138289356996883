import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AutoLaTeX from 'react-autolatex';
import Masonry from 'react-masonry-css';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Breadcrumbs from 'components/breadcrumbs';
import ObjectiveContext from 'components/objective-context';
import QuestionStatusChip from 'components/question-status-chip';
import QuestionStatusCounts from 'components/question-status-counts';
import useFetchQuestions from 'helpers/hooks/use-fetch-questions';
import API from 'services';
import useStore from 'store';
import PreviewDialog from 'components/preview-dialog';
import { QUESTION_STATUS } from '../../constants';
import useAuthStore from '../../auth/store';
import useStyles from './styles';

const { DRAFT, FOR_REVIEW, ACCEPTED, FOR_ARCHIVE, ARCHIVED } = QUESTION_STATUS;
const orderedStatuses = [DRAFT, FOR_REVIEW, ACCEPTED];

const compareStatusAndCreationDate = (a, b) =>
  orderedStatuses.indexOf(a.status) - orderedStatuses.indexOf(b.status) ||
  new Date(b.creationDate) - new Date(a.creationDate);

const userSelector = (state) => state.isEditor;
const questionsSelector = (state) => [
  state.setQuestions,
  state.addQuestion,
  state.editQuestion,
];

// TODO: Review these options. This was taken from the existing ProseMirror example app.
const autoLatexOptions = {
  delimiters: [{ left: '$', right: '$', display: false }],
  errorCallback(msg, err) {
    // eslint-disable-next-line no-console
    console.error('[Error]', msg, err);
  },
};

const breadcrumbLinks = [
  {
    text: 'Objective',
  },
];

function Objective() {
  const classes = useStyles();
  const { id } = useParams();
  const isEditor = useAuthStore(userSelector);
  const ignoredStatuses = [ARCHIVED];

  if (!isEditor()) {
    ignoredStatuses.push(FOR_ARCHIVE);
  }

  const objective = useStore((state) =>
    state.objectives.find((obj) => obj.id === id)
  );
  const [sortedQuestions, setSortedQuestions] = useState([]);
  const [, addQuestion, editQuestion] = useStore(questionsSelector);
  const history = useHistory(); // eslint-disable-line no-unused-vars
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewQuestion, setPreviewQuestion] = useState();

  useEffect(() => {
    const unsorted = (objective?.questions ?? []).filter(
      (q) => !ignoredStatuses.includes(q.status)
    );
    const sorted = [...unsorted].sort(compareStatusAndCreationDate);

    setSortedQuestions(sorted);

    // Ensure the preview question is up-to-date as it reads from the sorted questions.
    if (previewQuestion) {
      const previewQ = sorted.find(
        (sortedQ) => sortedQ.id === previewQuestion.id
      );

      setPreviewQuestion(previewQ);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objective]);

  // Get questions for this objective, adds them to objective object.
  useFetchQuestions(id);

  const handleQuestionCreate = async (duplicate) => {
    const body = {};
    let question;

    if (duplicate) {
      body.duplicate = duplicate;
    }

    try {
      question = await API.post(`objectives/${id}/questions`, body);
    } catch (err) {
      // TODO: handle this.
    }

    addQuestion(id, question);
    if (!duplicate) {
      history.push({ pathname: `/objectives/${id}/questions/${question.id}` });
    }
  };

  const handlePreviewClick = (question) => {
    setPreviewQuestion(question);
    setPreviewOpen(true);
  };

  const handleArchiveClick = async (questionId) => {
    let res;

    try {
      res = await API.post(`objectives/${id}/questions/${questionId}/archive`);
    } catch (err) {
      // TODO: handle this.
    }

    if (res && res.id) {
      editQuestion(res);
    }
  };

  const handleRevertClick = async (questionId) => {
    let res;

    try {
      res = await API.post(
        `objectives/${id}/questions/${questionId}/archive-reject`
      );
    } catch (err) {
      // TODO: handle this.
    }

    if (res && res.id) {
      editQuestion(res);
    }
  };

  // TODO: how do we want to render the markup for the question itself?
  return objective?.questions ? (
    <div className="page-objective">
      <Breadcrumbs links={breadcrumbLinks} />
      <ObjectiveContext objective={objective} />
      <div className={classes.title}>
        <Typography variant="h2" className={classes.subtitle}>
          Questions
        </Typography>
        <QuestionStatusCounts
          questionCountsByStatus={objective.questionCounts}
        />
      </div>
      <Masonry
        breakpointCols={{ default: 3, 1200: 2, 800: 1 }}
        className={classes.container}
        columnClassName={classes.column}
      >
        {sortedQuestions.map((question) => (
          <Card className={classes.root} key={question.id}>
            <div className={classes.header}>
              <Typography variant="h4">Question</Typography>
              <QuestionStatusChip status={question.status} />
            </div>
            <CardContent className={classes.content}>
              <AutoLaTeX options={autoLatexOptions}>{question.title}</AutoLaTeX>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => handleQuestionCreate(question.id)}
              >
                Duplicate
              </Button>
              {question?.status === 'draft' && (
                <Button
                  size="small"
                  component={Link}
                  to={`/objectives/${id}/questions/${question.id}`}
                >
                  Edit
                </Button>
              )}
              <Button size="small" onClick={() => handlePreviewClick(question)}>
                Preview
              </Button>
              {(question.status === DRAFT ||
                (isEditor() && question.status === FOR_ARCHIVE)) && (
                <Button
                  size="small"
                  onClick={() => handleArchiveClick(question.id)}
                >
                  Archive
                </Button>
              )}
              {isEditor() && question.status === FOR_ARCHIVE && (
                <Button
                  size="small"
                  onClick={() => handleRevertClick(question.id)}
                >
                  Revert to draft
                </Button>
              )}
            </CardActions>
          </Card>
        ))}
      </Masonry>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleQuestionCreate()}
      >
        Add question
      </Button>
      <PreviewDialog
        isOpen={previewOpen}
        question={previewQuestion}
        onClose={() => setPreviewOpen(false)}
        questionStatus={previewQuestion?.status}
        onPreviewSubmit={() => setPreviewOpen(false)}
      />
    </div>
  ) : null;
}

export default Objective;
