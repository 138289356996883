import { useEffect, useState } from 'react';
import API from 'services';
import useStore from 'store';

export const ContentType = {
  PREVIEW: 'PREVIEW',
  FLAG: 'FLAG',
};

const editQuestionSelector = (state) => state.editQuestion;

function usePreviewLogic({
  fields,
  question,
  onClose,
  hasEditedFields,
  onPreviewSubmit,
}) {
  const [contentType, setContentType] = useState(ContentType.PREVIEW);
  const [commentary, setCommentary] = useState(question?.flag || '');
  const [flagSaved, setFlagSaved] = useState(false);
  const editQuestion = useStore(editQuestionSelector);

  useEffect(() => {
    setCommentary(question?.flag || '');
  }, [question]);

  const handleStatusChange = async (type) => {
    let submitRes;

    setFlagSaved(false);

    try {
      let questionToSave;

      if (hasEditedFields) {
        if (fields) {
          questionToSave = {
            ...question,
            ...fields,
          };
        } else {
          questionToSave = question;
        }

        await API.put(
          `objectives/${question.objectiveId}/questions/${question?.id}`,
          questionToSave
        );
      }

      submitRes = await API.post(
        `objectives/${question?.objectiveId}/questions/${question?.id}/${type}`
      );
    } catch (err) {
      // TODO: Handle this.
    }

    if (submitRes?.id) {
      editQuestion({
        ...question,
        ...fields,
        status: submitRes.status,
        flag: type === 'accept' ? '' : question.flag,
      });

      if (onPreviewSubmit) {
        onPreviewSubmit();
      }
    }
  };

  const handleFlagClick = () => {
    setFlagSaved(false);
    setContentType(ContentType.FLAG);
  };

  const handleFlagCancel = () => {
    setContentType(ContentType.PREVIEW);
  };

  const handleFlagSave = async () => {
    let res;

    try {
      res = await API.post(
        `objectives/${question?.objectiveId}/questions/${question?.id}/flag`,
        {
          commentary,
        }
      );
    } catch (err) {
      // TODO: Handle this.
    }

    if (res?.id) {
      editQuestion(res);

      setContentType(ContentType.PREVIEW);
      setFlagSaved(true);
      onClose();
    }
  };

  return {
    contentType,
    commentary,
    flagSaved,
    setFlagSaved,
    setCommentary,
    handleFlagSave,
    handleFlagClick,
    handleFlagCancel,
    handleStatusChange,
  };
}

export default usePreviewLogic;
