import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

function NavBar() {
  const { pathname } = useLocation();

  const title =
    (pathname.indexOf('/preview') !== -1 && 'Preview Question') ||
    (pathname.indexOf('/questions/') !== -1 && 'Edit Question') ||
    (pathname.indexOf('/objectives/') !== -1 && 'Objective Summary') ||
    (pathname === '/' && 'Dashboard');

  return (
    <AppBar>
      <Container disableGutters>
        <Toolbar>
          <Typography variant="h2">{title}</Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
