import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, Chip } from '@material-ui/core';
import useStyles from './styles';

function QuestionStatusChip({ status, count }) {
  const showAvatar = typeof count === 'number';
  const classes = useStyles({ status, showAvatar })();
  const label = status
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (c) => c.toUpperCase());

  const avatar = showAvatar ? <Avatar>{count}</Avatar> : null;

  return (
    <Chip label={label} className={classes.chip} avatar={avatar} size="small" />
  );
}

QuestionStatusChip.defaultProps = { count: null };
QuestionStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default QuestionStatusChip;
