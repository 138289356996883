export const fieldNames = [
  'title',
  'answer',
  'misconception1',
  'misconception2',
  'misconception3',
];

export const headings = {
  title: 'Question',
  answer: 'Correct answer',
  misconception1: 'Misconception 1',
  misconception2: 'Misconception 2',
  misconception3: 'Misconception 3',
};
