import { useEffect } from 'react';
import useStore from 'store';
import API from 'services';

const questionsSelector = (state) => [state.setQuestions];

// Get questions for an objective, add them to that objective object.
function useFetchQuestions(objectiveId) {
  const objective = useStore((state) =>
    state.objectives.find((obj) => obj.id === objectiveId)
  );
  const hasObjective = !!objective;

  const [setQuestions] = useStore(questionsSelector);

  useEffect(() => {
    const getQuestions = async () => {
      const res = await API.get(`objectives/${objectiveId}/questions`);
      const newQuestions = res?.questions ?? [];

      setQuestions(objectiveId, newQuestions);
    };

    if (hasObjective) {
      getQuestions();
    }
  }, [hasObjective, objectiveId, setQuestions]);
}

export default useFetchQuestions;
