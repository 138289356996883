import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { QuestionPropType } from 'helpers/prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from 'components/dialog';
import useAuthStore from 'auth/store';
import PreviewContent from './preview-content';
import FlagContent from './flag-content';
import usePreviewLogic, { ContentType } from './hooks/use-preview-logic';
import useStyles from './styles';
import './styles.scss';

const userSelector = (state) => [state.isEditor, state.isAuthor];
const isPreviewContent = (contentType) => contentType === ContentType.PREVIEW;

function PreviewDialog({
  isOpen,
  onClose,
  fields,
  question,
  hasEditButton,
  hasEditedFields,
  onPreviewSubmit,
}) {
  const [error, setError] = useState(false);
  const [isEditor, isAuthor] = useAuthStore(userSelector);
  const {
    commentary,
    contentType,
    setCommentary,
    handleFlagSave,
    handleFlagClick,
    handleFlagCancel,
    handleStatusChange,
  } = usePreviewLogic({
    fields,
    onClose,
    question,
    hasEditedFields,
    onPreviewSubmit,
  });
  const classes = useStyles();

  const handleClose = () => {
    setError(false);
    setCommentary('');

    onClose();
  };

  const previewButtons = (
    <div className={classes.buttonContainer}>
      {isEditor() && (
        <>
          {(question?.status === 'for_review' ||
            question?.status === 'accepted') && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleFlagClick}
              className={classes.button}
            >
              Flag
            </Button>
          )}
          {(question?.status === 'draft' ||
            question?.status === 'for_review') && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleStatusChange('accept')}
              className={classes.button}
            >
              Accept
            </Button>
          )}
        </>
      )}
      {isAuthor() && (
        <>
          {hasEditButton && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              className={classes.button}
              to={`/objectives/${question?.objectiveId}/questions/${question?.id}`}
            >
              Edit
            </Button>
          )}
          {question?.status === 'draft' && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => handleStatusChange('submit')}
            >
              Submit for review
            </Button>
          )}
          {question?.status === 'for_review' && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => handleStatusChange('withdraw')}
            >
              Revert to draft
            </Button>
          )}
        </>
      )}
      <Button variant="contained" color="primary" onClick={handleClose}>
        Close
      </Button>
    </div>
  );

  const flagButtons = (
    <div className={classes.buttonContainer}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleFlagSave}
        className={classes.button}
        disabled={commentary?.trim().length === 0}
      >
        Save
      </Button>
      <Button
        variant="contained"
        onClick={handleFlagCancel}
        className={classes.button}
      >
        Cancel
      </Button>
    </div>
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={
        isPreviewContent(contentType) ? 'Question Preview' : 'Flag Question'
      }
      className="PreviewDialog"
      actions={isPreviewContent(contentType) ? previewButtons : flagButtons}
    >
      {error && (
        <Alert severity="error" variant="filled">
          You have at least one empty answer, please fill them all out
        </Alert>
      )}
      {isPreviewContent(contentType) ? (
        <PreviewContent fields={fields || question} />
      ) : (
        <FlagContent
          commentary={commentary}
          onChange={(c) => setCommentary(c)}
        />
      )}
    </Dialog>
  );
}

PreviewDialog.defaultProps = {
  fields: null,
  question: null,
  hasEditButton: false,
  hasEditedFields: false,
  onPreviewSubmit: null,
};

PreviewDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasEditButton: PropTypes.bool,
  fields: PropTypes.instanceOf(Object),
  question: QuestionPropType,
  hasEditedFields: PropTypes.bool,
  onPreviewSubmit: PropTypes.func,
};

export default PreviewDialog;
