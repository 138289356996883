import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(5),
      right: theme.spacing(5),
    },
    '& svg': {
      display: 'block',
    },
  },
  link: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export default useStyles;
