import { API as Amplify } from 'aws-amplify';
import useAuthStore from '../auth/store';

const root = process.env.REACT_APP_AWS_API_NAME;
const headers = {};

useAuthStore.subscribe(
  (jwt) => {
    headers.Authorization = jwt ? `Bearer ${jwt}` : undefined;
  },
  (state) => state.jwt
);

// Lightweight wrapper around AWS Amplify's API module.
const API = {
  async post(endpoint, body) {
    return Amplify.post(root, endpoint, {
      headers,
      body,
    }).catch((error) => {
      const err = new Error(error?.response?.data?.message);

      err.code = error?.response?.data?.code;
      throw err;
    });
  },

  async put(endpoint, body) {
    return Amplify.put(root, endpoint, {
      headers,
      body,
    }).catch((error) => {
      const err = new Error(error?.response?.data?.message);

      err.code = error?.response?.data?.code;
      throw err;
    });
  },

  async get(endpoint) {
    return Amplify.get(root, endpoint, {
      headers,
    }).catch((error) => {
      const err = new Error(error?.response?.data?.message);

      err.code = error?.response?.data?.code;
      throw err;
    });
  },

  async delete(endpoint) {
    return Amplify.del(root, endpoint, {
      headers,
    }).catch((error) => {
      const err = new Error(error?.response?.data?.message);

      err.code = error?.response?.data?.code;
      throw err;
    });
  },
};

export default API;
