import { useState } from 'react';
import PropTypes from 'prop-types';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { NavLink } from 'react-router-dom';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import useStyles from './styles';

function FloatingSpeedDial({
  handleSave,
  handlePreviewOpen,
  objectiveLink,
  isEdited,
}) {
  const classes = useStyles();
  const actions = [
    {
      icon: <SaveIcon />,
      name: 'Save',
      onClick: handleSave,
      isDisabled: !isEdited,
    },
    { icon: <VisibilityIcon />, name: 'Preview', onClick: handlePreviewOpen },
    {
      icon: (
        <NavLink activeClassName={classes.link} to={objectiveLink}>
          <NavigateBeforeIcon />
        </NavLink>
      ),
      name: 'Back to Objective',
    },
  ];
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    open ? setOpen(false) : setOpen(true);
  };

  return (
    <SpeedDial
      ariaLabel="speed dial"
      icon={<SpeedDialIcon />}
      className={classes.speedDial}
      onClick={() => toggleOpen()}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      direction="left"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.isDisabled ? '' : action.name}
          onClick={action?.onClick}
          disabled={action.isDisabled}
        />
      ))}
    </SpeedDial>
  );
}

FloatingSpeedDial.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handlePreviewOpen: PropTypes.func.isRequired,
  objectiveLink: PropTypes.string.isRequired,
  isEdited: PropTypes.bool.isRequired,
};

export default FloatingSpeedDial;
