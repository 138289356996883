import { useEffect, useState, useCallback } from 'react';
import { fieldNames } from 'pages/question/edit/field-constants';
import { useParams } from 'react-router-dom';
import API from 'services';
import useStore from 'store';
import useFetchQuestions from 'helpers/hooks/use-fetch-questions';
import { getInitialFields, getQuestions } from './field-helpers';

const editQuestionSelector = (state) => state.editQuestion;

function useQuestionEdit() {
  const { objectiveId, questionId } = useParams();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fields, setFields] = useState();

  const selector = (state) =>
    state.objectives.find((obj) => obj.id === objectiveId);
  const objective = useStore(useCallback(selector, [objectiveId]));

  let question;
  if (objective?.questions) {
    question = objective.questions.find((q) => q.id === questionId);
  }
  const editQuestion = useStore(editQuestionSelector);

  let hasEditedFields = false;
  if (fields) {
    hasEditedFields = Object.keys(fields).some((key) => fields[key]?.edited);
  }

  // Get questions for this objective, adds them to objective object.
  useFetchQuestions(objectiveId);

  useEffect(() => {
    setFields(getInitialFields(question));
  }, [question]);

  const updateFields = (updatedDoc, fieldName, hasChanged) => {
    const fieldToUpdate = fields[fieldName];

    setFields({
      ...fields,
      [fieldName]: {
        ...fieldToUpdate,
        currentDoc: updatedDoc,
        edited: hasChanged,
      },
    });

    return { updated: true };
  };

  const resetField = useCallback(
    (fieldName) => {
      const fieldToUpdate = fields[fieldName];

      setFields({
        ...fields,
        [fieldName]: {
          ...fieldToUpdate,
          currentDoc: fieldToUpdate.initialDoc,
          edited: false,
        },
      });
    },
    [fields]
  );

  // Reset all fields to non edited state
  const resetAllFields = () => {
    const updatedFields = {};

    fieldNames.forEach((fieldName) => {
      const currentField = fields[fieldName];

      updatedFields[fieldName] = {
        ...currentField,
        edited: false,
      };
    });

    setFields(updatedFields);
  };

  const handleConfirmed = async () => {
    let res;

    setSaveDialogOpen(false);

    // Extract only values to posted, each modified document.
    const postObject = getQuestions(fields);

    try {
      res = await API.put(
        `objectives/${objectiveId}/questions/${questionId}`,
        postObject
      );
    } catch (err) {
      // TODO: Handle this.
    }

    // Reset all fields to not modified now that we're saving
    resetAllFields();

    if (res?.id) {
      editQuestion(res);
    }
  };

  const handleConfirmClose = () => {
    setSaveDialogOpen(false);
  };

  const handleExitClose = () => {
    setExitDialogOpen(false);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handleSave = () => {
    if (question.status === 'for_review') {
      setSaveDialogOpen(true);
    } else {
      handleConfirmed();
    }
  };

  const handleStatusUpdate = () => {
    setPreviewOpen(false);
  };

  return {
    fields,
    question,
    objective,
    previewOpen,
    resetField,
    updateFields,
    handleSave,
    saveDialogOpen,
    exitDialogOpen,
    hasEditedFields,
    handleConfirmed,
    handlePreviewOpen,
    handlePreviewClose,
    handleExitClose,
    handleConfirmClose,
    handleStatusUpdate,
  };
}

export default useQuestionEdit;
