import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '15px 0 10px 0',
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.4),
    width: 20,
    height: 20,
  },
}));

export default useStyles;
