import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ProseMirror from 'prosemirror';
import uploadImage from './upload-image';
import './styles.scss';

function QuestionField({
  onDocUpdated,
  fieldName,
  initialDoc,
  resetField,
  edited,
  title,
  isAnswer,
}) {
  // Count to indicate that the document has been reset
  const [resetCount, setResetCount] = useState(0);
  const toolbarOptions = isAnswer
    ? ['effects', 'mathquill', 'image', 'history']
    : ['style', 'effects', 'align', 'list', 'mathquill', 'image', 'history'];

  const handleReset = (e) => {
    e.preventDefault();

    resetField(fieldName);

    // Increment of count indicates to ProseMirror to reset to whatever
    // was initially passed in as the editorDoc prop, which is initialDoc.
    setResetCount(resetCount + 1);
  };

  return (
    <div className={cn('QuestionField', { 'QuestionField--edited': edited })}>
      <Typography className="QuestionField__heading" variant="h2">
        {title}
      </Typography>
      <ProseMirror
        docFormat="html"
        editorDoc={initialDoc}
        resetCount={resetCount}
        onDocUpdated={onDocUpdated}
        className="QuestionField__input"
        toolbarOptions={toolbarOptions}
        uploadFile={uploadImage}
      />
      <div className="QuestionField__reset">
        <Link href="#" underline="always" onClick={handleReset}>
          Reset Changes
        </Link>
      </div>
    </div>
  );
}

QuestionField.propTypes = {
  edited: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialDoc: PropTypes.string.isRequired,
  onDocUpdated: PropTypes.func.isRequired,
  resetField: PropTypes.func.isRequired,
  isAnswer: PropTypes.bool,
};

QuestionField.defaultProps = {
  isAnswer: false,
};

export default QuestionField;
