import API from 'services';

const uploadImage = async (file) => {
  const data = await readFileAsync(file);
  const base64 = data.split(',')[1];
  const { imageUrl } = await API.post('upload-image', {
    filename: file.name,
    base64,
  });

  return imageUrl;
};

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file'));
    };

    reader.readAsDataURL(file);
  });
};

export default uploadImage;
