import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  questionEdit: {
    paddingBottom: (keyboardHeight) => `${keyboardHeight}px`,
  },
  buttons: {
    padding: '10px',
    position: 'fixed',
    bottom: '20px',
    right: '20px',

    '& .MuiButtonBase-root': {
      marginRight: '10px',
    },
  },
  save: {
    position: 'fixed',
  },
  topSection: {
    padding: '10px',
  },
  questionList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  questionItem: {
    margin: 0,
    padding: 0,
  },
});

export default makeStyles(styles);
