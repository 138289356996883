import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import useStyles from './styles';

function Breadcrumbs({ links }) {
  const classes = useStyles();

  return links?.length ? (
    <MaterialBreadcrumbs className={classes.root} aria-label="breadcrumb">
      <Link
        to="/"
        color="inherit"
        key="home"
        className={classes.link}
        component={RouterLink}
      >
        <HomeIcon className={classes.icon} />
        Home
      </Link>
      {links.map((link) =>
        link.href ? (
          <Link
            color="inherit"
            to={link.href}
            className={classes.link}
            component={RouterLink}
            key={link.text}
          >
            {link.text}
          </Link>
        ) : (
          <Typography
            color="textPrimary"
            className={classes.link}
            key={link.text}
          >
            {link.text}
          </Typography>
        )
      )}
    </MaterialBreadcrumbs>
  ) : null;
}

Breadcrumbs.defaultProps = {
  links: null,
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
};

export default Breadcrumbs;
