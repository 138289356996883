const themeOverrides = {
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    // Material UI provides crazy heading sizes in the default theme.
    // Here, we adjust to the W3C recommend sizes.
    h1: { fontSize: '2rem', margin: '30px 0 20px 0' },
    h2: { fontSize: '1.5rem', margin: '10px 0 10px 0' },
    h3: { fontSize: '1.17rem', margin: '10px 0 10px 0' },
    h4: { fontSize: '1.00rem' },
    h5: { fontSize: '0.83rem' },
    h6: { fontSize: '0.67rem' },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          paddingTop: '70px',
        },
      },
    },
  },
};

export default themeOverrides;
