import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';

function FlagContent({ commentary, onChange }) {
  const classes = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;

    onChange(value);
  };

  return (
    <TextField
      label="Flag commentary"
      value={commentary}
      onChange={handleChange}
      className={classes.root}
    />
  );
}

FlagContent.propTypes = {
  commentary: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FlagContent;
