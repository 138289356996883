import PropTypes from 'prop-types';
import React from 'react';
import { QUESTION_STATUS } from '../../constants';
import QuestionStatusChip from '../question-status-chip';
import useStyles from './styles';

const { DRAFT, FOR_REVIEW, ACCEPTED } = QUESTION_STATUS;
const orderedStatuses = [DRAFT, FOR_REVIEW, ACCEPTED];

function QuestionStatusCounts({ questionCountsByStatus }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {orderedStatuses.map((status) => (
        <QuestionStatusChip
          key={status}
          status={status}
          count={questionCountsByStatus[status]}
          className={classes.root}
        />
      ))}
    </div>
  );
}

QuestionStatusCounts.propTypes = {
  questionCountsByStatus: PropTypes.shape({
    [DRAFT]: PropTypes.number.isRequired,
    [FOR_REVIEW]: PropTypes.number.isRequired,
    [ACCEPTED]: PropTypes.number.isRequired,
  }).isRequired,
};

export default QuestionStatusCounts;
