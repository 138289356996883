import PropTypes from 'prop-types';
import cn from 'classnames';
import MuiDialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';
import './styles.scss';

function Dialog({
  actions,
  className,
  title,
  isOpen,
  onClose,
  icon,
  children,
}) {
  const classes = useStyles();

  return (
    <MuiDialog
      open={isOpen}
      onClose={onClose}
      className={cn(className, classes.root)}
    >
      {icon}
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h2">{title}</Typography>
        {onClose && (
          <CloseIcon className="Dialog__close-btn" onClick={onClose} />
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  icon: null,
  className: null,
  actions: null,
};

Dialog.propTypes = {
  actions: PropTypes.element,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
