import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  col: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tooltip: {
    maxWidth: 700,
    margin: '-10px 0',
    fontSize: '13px',
  },
});

export default makeStyles(styles);
