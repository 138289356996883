import { useEffect } from 'react';
import API from 'services';
import useStore from 'store';
import useAuthStore from 'auth/store';

const userSelector = (state) => [state.user._id, state.isEditor];
const objectivesSelector = (state) => [state.objectives, state.setObjectives];

function useFetchObjectives() {
  const [userId] = useAuthStore(userSelector);
  const [, setObjectives] = useStore(objectivesSelector);

  // Fetch and store objectives in global state (Zustand)
  useEffect(() => {
    (async () => {
      let res;

      try {
        res = await API.get(`users/${userId}/objectives`);
      } catch (err) {
        // TODO: do we want to handle this to display a more useful message?
      }

      if (res) {
        const newObjectives = res?.objectives ?? [];

        setObjectives(newObjectives);
      }
    })();
  }, [setObjectives, userId]);
}

export default useFetchObjectives;
