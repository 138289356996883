import PropTypes from 'prop-types';

export const QuestionPropType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  answer: PropTypes.string,
  status: PropTypes.string,
  misconception1: PropTypes.string,
  misconception2: PropTypes.string,
  misconception3: PropTypes.string,
  objectiveId: PropTypes.string,
});
