import PropTypes from 'prop-types';
import Dialog from 'components/dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './styles.scss';

function ConfirmDialog({
  title,
  text,
  isOpen,
  onClose,
  onConfirm,
  buttonText,
  buttonIcons,
}) {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={title}>
      <Typography paragraph align="center" className="ConfirmDialog__copy">
        {text}
      </Typography>
      <div className="ConfirmDialog__buttons">
        <Button
          color="primary"
          variant="contained"
          onClick={onConfirm}
          startIcon={buttonIcons.confirmButton}
          className="ConfirmDialog__confirm-btn"
        >
          {buttonText.confirmButton}
        </Button>
        <Button variant="contained" onClick={onClose}>
          {buttonText.cancelButton}
        </Button>
      </div>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  text: null,
  buttonText: {
    confirmButton: 'OK',
    cancelButton: 'Cancel',
  },
  buttonIcons: {
    confirmButton: null,
    cancelButton: null,
  },
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  buttonText: PropTypes.shape({
    confirmButton: PropTypes.string,
    cancelButton: PropTypes.string,
  }),
  buttonIcons: PropTypes.shape({
    confirmButton: PropTypes.element,
    cancelButton: PropTypes.element,
  }),
};

export default ConfirmDialog;
