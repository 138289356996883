import { fieldNames, headings } from './field-constants';

const startingDoc = '<p></p>';

export function getInitialFields(question, isEdited) {
  if (!question) return null;

  const fields = {};

  fieldNames.forEach((fieldName) => {
    const doc = question[fieldName] || startingDoc;

    fields[fieldName] = {
      initialDoc: doc,
      currentDoc: doc,
      heading: headings[fieldName],
      edited: isEdited || false,
    };
  });

  return fields;
}

export function getQuestions(fields) {
  if (!fields) return null;

  const questions = {};

  fieldNames.forEach((fieldName) => {
    questions[fieldName] = fields[fieldName].currentDoc;
  });

  return questions;
}
