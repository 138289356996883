import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  questionText: {
    fontSize: 28,
    fontWeight: 'bold',
  },
  column: {
    width: '100%',
    '& img': {
      maxWidth: '80%',
      display: 'block',
      margin: '0 auto',
    },
  },
  '@media screen and (min-width: 800px)': {
    cols: {
      display: 'flex',
    },
    column: {
      width: '390px',
      '& img': {
        maxWidth: '360px',
      },
      marginRight: '10px',
    },
  },
  waiting: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
}));

export default useStyles;
