import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AuthWrapper from './auth/wrapper';
import Router from './router';
import themeOverrides from './styles/themeOverrides';
import 'katex/dist/katex.min.css';

// Configure Sentry.
if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// Configure AWS Amplify.
Amplify.configure({
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_AWS_API_NAME,
        endpoint: process.env.REACT_APP_AWS_APIGATEWAY_URL,
        region: process.env.REACT_APP_AWS_REGION,
      },
    ],
  },
});

const theme = createMuiTheme(themeOverrides);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthWrapper>
        <Router />
      </AuthWrapper>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
