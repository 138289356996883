import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AutoLaTeX from 'react-autolatex';
import useStyles from './styles';

// TODO: Review these options. This was taken from the existing ProseMirror example app.
const autoLatexOptions = {
  delimiters: [{ left: '$', right: '$', display: false }],
  errorCallback(msg, err) {
    // eslint-disable-next-line no-console
    console.error('[Error]', msg, err);
  },
};

function Answer({ html, isCorrect, height }) {
  const classes = useStyles();

  const className = cn(classes.root, {
    [classes.correct]: isCorrect,
  });

  return (
    <div className={className} style={{ height }}>
      <AutoLaTeX options={autoLatexOptions}>{html}</AutoLaTeX>
    </div>
  );
}

Answer.defaultProps = {
  isCorrect: false,
  height: null,
};

Answer.propTypes = {
  isCorrect: PropTypes.bool,
  height: PropTypes.number,
  html: PropTypes.string.isRequired,
};

export default Answer;
