import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    textAlign: 'center',
    fontSize: '24px',
    paddingRight: '60px',
  },
}));

export default useStyles;
