import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import NavBar from 'components/nav-bar';
import Home from '../pages/home';
import Objective from '../pages/objective';
import QuestionEdit from '../pages/question/edit';
import useFetchObjectives from './use-fetch-objectives';

function App() {
  useFetchObjectives();

  return (
    <Router>
      <NavBar />
      <Container>
        <Switch>
          <Route path="/objectives/:objectiveId/questions/:questionId">
            <QuestionEdit />
          </Route>
          <Route path="/objectives/:id">
            <Objective />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
