/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import useStore from 'store';
import Breadcrumbs from 'components/breadcrumbs';
import QuestionStatusCounts from 'components/question-status-counts';
import ObjectiveCell from 'components/objective-cell';
import { QUESTION_STATUS } from '../../constants';
import useAuthStore from '../../auth/store';
import useStyles from './styles';

const { FOR_REVIEW, ACCEPTED } = QUESTION_STATUS;
const userSelector = (state) => [state.user._id, state.isEditor];
const objectivesSelector = (state) => [state.objectives];

function questionStatusSortComparator(_, __, a, b) {
  const aQs = a.row.questionCounts;
  const bQs = b.row.questionCounts;
  return aQs[FOR_REVIEW] + aQs[ACCEPTED] - bQs[FOR_REVIEW] - bQs[ACCEPTED];
}

function codeAndNameSortComparator(_, __, { row: a }, { row: b }) {
  return (
    a.syllabusCode.localeCompare(b.syllabusCode) ||
    a.stage - b.stage ||
    a.unitOrderInStage - b.unitOrderInStage ||
    a.statementOrderInUnit - b.statementOrderInUnit ||
    a.orderInStatement - b.orderInStatement
  );
}

function Home() {
  const [, isEditor] = useAuthStore(userSelector);
  const [objectives] = useStore(objectivesSelector);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const newColumns = [
      {
        field: 'assignedTo',
        headerName: 'Assigned to',
        flex: 0,
        width: 200,
      },
      {
        field: 'objectiveCodeAndName',
        headerName: 'Objective',
        sortComparator: codeAndNameSortComparator,
        flex: 1,
        renderCell: ({ row }) => {
          return <ObjectiveCell name={row.objectiveCodeAndName} />;
        },
      },
      {
        field: 'questionCountsText',
        headerName: 'Questions',
        sortComparator: questionStatusSortComparator,
        flex: 0,
        width: 300,
        renderCell: ({ row }) => (
          <QuestionStatusCounts questionCountsByStatus={row.questionCounts} />
        ),
      },
    ];

    if (!isEditor()) newColumns.shift();

    setRows(objectives);
    setColumns(newColumns);
  }, [objectives, isEditor]);

  const handleRowClick = (row) => {
    history.push(`/objectives/${row.id}`);
  };

  return (
    <div className={classes.dashboard}>
      <Breadcrumbs />
      <Typography variant="h1">Your objectives</Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        pageSize={20}
        rowHeight={75}
        autoHeight
      />
    </div>
  );
}

export default Home;
