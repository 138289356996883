import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '10px',
      width: '100%',
    },
    button: {
      marginRight: '10px',
      marginButton: '10px',
    },
  };
});

export default useStyles;
