import { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import QuestionField from 'pages/question/field';
import SaveIcon from '@material-ui/icons/Save';
import blue from '@material-ui/core/colors/blue';
import { KeypadContainer } from 'keyboard';
import Breadcrumbs from 'components/breadcrumbs';
import ObjectiveContext from 'components/objective-context';
import { getQuestions } from 'pages/question/edit/field-helpers';
import ConfirmDialog from 'components/confirm-dialog';
import PreviewDialog from 'components/preview-dialog';
import FloatingSpeedDial from 'components/floating-speed-dial';
import useQuestionEdit from './use-question-edit';
import useStyles from './styles';

function QuestionEdit() {
  const {
    fields,
    question,
    objective,
    previewOpen,
    resetField,
    handleSave,
    updateFields,
    saveDialogOpen,
    hasEditedFields,
    handleConfirmed,
    handlePreviewOpen,
    handleConfirmClose,
    handlePreviewClose,
  } = useQuestionEdit();
  // Hardcoded for now
  const appBarHeight = 64;
  const scrollToMargin = 40;

  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const handleKeyboardChange = (event) => {
      const { changeType, height, activeElement } = event.detail;
      setKeyboardHeight(height);
      if (changeType === 'show') {
        setTimeout(() => {
          if (activeElement && !isScrolledIntoView(activeElement, height)) {
            // Attempt to scroll into view if hidden
            window.scroll(
              0,
              findTop(activeElement) - appBarHeight - scrollToMargin
            );
          }
        });
      }
    };
    document.addEventListener('keyboard-change', handleKeyboardChange);
    return function cleanup() {
      document.removeEventListener('keyboard-change', handleKeyboardChange);
    };
  }, []);

  const isScrolledIntoView = (element, bottomPadding) => {
    const rect = element.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    const isVisible =
      elemTop >= 0 && elemBottom <= window.innerHeight - bottomPadding;
    return isVisible;
  };

  const findTop = (element) => {
    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
  };

  const breadcrumbLinks = [
    {
      text: 'Objective',
      href: `/objectives/${objective?.id}`,
    },
    {
      text: 'Edit Question',
    },
  ];

  let isEdited;
  if (fields) {
    isEdited = Object.keys(fields).some((key) => fields[key]?.edited);
  }

  const handlePreviewSubmit = () => {
    history.push(`/objectives/${question?.objectiveId}`);
  };

  const classes = useStyles(keyboardHeight);

  return objective?.questions && fields ? (
    <div className={classes.questionEdit}>
      <Breadcrumbs links={breadcrumbLinks} />
      <div className={classes.topSection}>
        <ObjectiveContext objective={objective} />
      </div>
      <ul className={classes.questionList}>
        {Object.keys(fields).map((key) => (
          <li key={key} className={classes.questionItem}>
            <QuestionField
              fieldName={key}
              title={fields[key].heading}
              currentDoc={fields[key].currentDoc}
              initialDoc={fields[key].initialDoc}
              edited={fields[key].edited}
              resetField={resetField}
              onDocUpdated={(doc, hasChanged) =>
                updateFields(doc, key, hasChanged)
              }
              isAnswer={key !== 'title'}
            />
          </li>
        ))}
      </ul>
      <FloatingSpeedDial
        handleSave={handleSave}
        handlePreviewOpen={handlePreviewOpen}
        objectiveLink={`/objectives/${objective?.id}`}
        isEdited={isEdited}
      />
      <ConfirmDialog
        isOpen={saveDialogOpen}
        onConfirm={handleConfirmed}
        onClose={handleConfirmClose}
        text="Are you sure?"
        title="Saving will revert this question to draft mode"
        buttonText={{
          confirmButton: 'Save',
          cancelButton: 'Cancel',
        }}
        buttonIcons={{
          confirmButton: <SaveIcon />,
        }}
      />
      <Prompt
        when={hasEditedFields}
        message={() =>
          'You have unsaved changes. \nAre you sure you want to leave this page?'
        }
      />
      <PreviewDialog
        question={question}
        isOpen={previewOpen}
        onClose={handlePreviewClose}
        fields={getQuestions(fields)}
        hasEditedFields={hasEditedFields}
        onPreviewSubmit={handlePreviewSubmit}
      />
      <KeypadContainer
        modules={['NUMBERS', 'BASIC_FUNCTIONS', 'ADVANCED_FUNCTIONS']}
        materialColor={blue}
      />
    </div>
  ) : null;
}

export default QuestionEdit;
