import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    maxWidth: '600px',
    '& .ql-editor': {
      padding: '0',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: theme.palette.primary.dark,
    color: 'white',
  },
  content: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '0', // MUI style override
    },
  },
  container: {
    display: 'flex',
    marginLeft: -theme.spacing(2), // gutter size offset
    width: 'auto',
    paddingBottom: '50px',
  },
  column: {
    paddingLeft: theme.spacing(2), // gutter size
    backgroundClip: 'padding-box',
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    '& > div': {
      margin: '20px 0',
    },
  },
  subtitle: {
    paddingTop: '20px',
  },
});

export default makeStyles(styles);
