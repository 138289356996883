import { makeStyles } from '@material-ui/core/styles';
import { QUESTION_STATUS } from '../../constants';

const { DRAFT, FOR_REVIEW, ACCEPTED, FOR_ARCHIVE } = QUESTION_STATUS;

const useStyles = ({ status }) =>
  makeStyles((theme) => {
    const colour = {
      [DRAFT]: theme.palette.primary,
      [FOR_REVIEW]: theme.palette.warning,
      [ACCEPTED]: theme.palette.success,
      [FOR_ARCHIVE]: theme.palette.grey,
    }[status];

    return {
      chip: {
        padding: theme.spacing(1, 0),
        backgroundColor: colour.main,
        color: 'white',
        '& .MuiAvatar-root': {
          backgroundColor: colour.dark,
          color: 'inherit',
          fontWeight: 800,
        },
      },
    };
  });

export default useStyles;
